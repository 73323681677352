import React, { useLayoutEffect, useState } from 'react'
import './clients.scss'
import loadable from '@loadable/component'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const Clients = React.memo(({clientsData, description}) => {

  const [clientDetails, setClientDetails] = useState([]);

  useLayoutEffect(() => {
    let clients = []
    clientsData.map(client => {
      return clients.push(client)
    })
    setClientDetails(clients)
  }, [clientsData])

  const formatArray = (clientsArray) => {
    const details = clientsArray
    let formattedArray = []
    while (details.length > 0) {
      let array = []
      array = details.splice(0, 3)
      formattedArray.push(array)
    }
    return formattedArray;
  }

  const clients = clientsArray => {
    return clientsArray.map(({ node }) => {
      return (
        <img
          loading="lazy"
          src={node.frontmatter.featuredImage}
          alt={node.frontmatter.title}
          key={node.fields.slug}
          className="client-img"
        />
      )
    })
  }
  return (
    <div
      className="clients-page d-lg-flex justify-content-lg-between"
      id="common-px"
    >
      <div className="title-container" id="clients">
        <Fade timemout={500}>
          <h1>Clients</h1>
        </Fade>
        <Fade timemout={1000}>
          <p className="title-dec">
            <Marked
              parserOptions={{ commonmark: true }}
              source={description}
            />
          </p>
        </Fade>
      </div>
      <div className="img-main-container">
        {formatArray(clientDetails).map((clientsArray, index) => (
          <div
            className="client-img-container d-lg-flex align-items-lg-center justify-content-lg-between"
            key={index}
          >
            {clients(clientsArray)}
          </div>
        ))}
      </div>
    </div>
  )
})

export default Clients

